html, body, #root {
  height: 100%;
}
body, #root {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}

.awssld {
  --loader-bar-color: #2e3192 !important;
}

.ui.secondary.pointing.menu.desktop-nav {
  border-bottom: none;
}

.ui.large.inverted.pointing.secondary.menu {
  padding-bottom: .8em;
}

.client-logos {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
}

.client-logos.bottom {
  padding-bottom: 2em;
}

.client-logos img {
  height: 100px;
  margin: 10px;
}

.roc {
  /*position: absolute;*/
  right: 0;
  left:0;
  text-align: right;
  padding: 2.4em;
  /*top: -50px;*/
  /*margin-top: 150px;*/
  font-weight: bold;
  color: #4183c4;
  font-size: 18px;
}

.roc > div {
  margin-top: 8px;
}

@media screen and (max-width: 570px) {
  .header.ui.horizontal.divider  {
    white-space: normal;
  }
}